import React from "react";
import "../styles/components/titles.module.scss";
import "../styles/components/header.module.scss";
import "../styles/components/content.module.scss";
import { Link } from "gatsby";
import { btn } from "../styles/components/buttons.module.scss";
const ThankYou = () => {
  return (
    <div className="container" style={{ textAlign: "center" }}>
      <h1 className="title">Thank You</h1>

      <h4 className="subtitle">We will be in touch soon!</h4>

      <Link to="/" className={btn}>
        Back Home
      </Link>
    </div>
  );
};

export default ThankYou;
